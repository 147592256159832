import React, { useEffect, useState } from "react";
import { setActiveChatId } from "../../domain/chats/active-chat-slice";
import { Chat, Chats, LoaderType, useFetchChatsQuery, useGetLoaderTypesQuery, useSetLoaderTypeMutation } from "../../infrastructure/api-slice";
import { useAppDispatch, useAppSelector } from "../../infrastructure/hooks";
import Button from "../Atoms/Buttons/Button";
import NavDropdown from "./Dropdown/NavDropdown";
import { setLoaderType as setAppLoaderType } from "../../domain/loader_types/loader_type-slice";
import Select from "react-tailwindcss-select";
import { Options, SelectValue } from "react-tailwindcss-select/dist/components/type";
import { setIsSearching } from "../../domain/search/search-slice";
import { setChats } from "../../domain/chats/chats-slice";

function PublicNavigation() {
  const dispatch = useAppDispatch();
  const activeChatId = useAppSelector(state => state.activeChat.id);
  const currentChats = useAppSelector(state => state.chats.chats);
  const isLoggedIn = useAppSelector(state => state.user.isLoggedIn);
  const [page, setPage] = useState(1);
  const page_size = 15;
  const [sessionId] = useState(Date.now());
  const [allLoadedChats, setAllLoadedChats] = useState<Chat[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const [hoveredChatId, setHoveredChatId] = useState<string | null>(null);

  const { data = {} as Chats, isFetching } = useFetchChatsQuery({ 
    amount: page_size, 
    page: page, 
    sessionId: sessionId 
  });

  const { data: loaderTypesData, isFetching: isFetchingLoaderTypes } = useGetLoaderTypesQuery();
  const [setLoaderTypes, result] = useSetLoaderTypeMutation();
  const [loaderTypes, setLoaderTypesState] = useState<LoaderType[]>([]);
  const [selectedLoaderType, setSelectedLoaderType] = useState<string>();
  const [loaderType, setLoaderType] = useState<SelectValue>(null);
  const [options, setOptions] = useState<Options>([]);
  const isSearching = useAppSelector(state => state.search.isSearching);
  const [fetchedChats, setfetchedChats] = useState(null);

  useEffect(handleNewChatsAdded, [currentChats]);

  useEffect(handleFetchedChats, [data.chats, isFetching, dispatch]);

  useEffect(handleScrollForPagination, [isFetching, hasMore]);

  useEffect(handleLoaderTypesFetching, [dispatch, isFetchingLoaderTypes, loaderTypesData]);

  useEffect(handleLoaderTypeMutationResult, [dispatch, result]);

  useEffect(handleSettingOptionsAndDefaultLoaderType, [dispatch, loaderTypes]);

  useEffect(handleSearchInputState, [isSearching]);

  useEffect(handlePreSelectedLoaderTypes, [loaderTypes, options]);

  useEffect(handleSettingLoaderType, [dispatch, selectedLoaderType, setLoaderTypes]);

  useEffect(addFetchedChatsToState, [fetchedChats, dispatch]);

  const setChat = (id: string) => {
    dispatch(setActiveChatId(id));
  };

  const handleChange = (value: any) => {
    if (!value) return;
    setChat("");
    setLoaderType(value);
    setSelectedLoaderType(value.value);
    dispatch(setAppLoaderType({ 
      name: value.value, 
      description: value.label 
    }));
  };

  function addFetchedChatsToState() {
    if (fetchedChats) {
      dispatch(setChats(fetchedChats));
      setfetchedChats(null);
    }
  }

  function handleNewChatsAdded() {
    if (currentChats && currentChats.length > 0) {
      setAllLoadedChats(prevChats => {
        const newChats = [...currentChats];
        
        prevChats.forEach(prevChat => {
          if (!newChats.find(chat => chat.chatId === prevChat.chatId)) {
            newChats.push(prevChat);
          }
        });

        return newChats;
      });
    }
  }

  function handleScrollForPagination() {
    const scrollContainer = document.getElementById('nav-parent');
    if (!scrollContainer) return;

    const handleScroll = () => {
      if (isFetching || !hasMore) return;

      const { scrollTop, scrollHeight, clientHeight } = scrollContainer;
      const threshold = 100;

      if (scrollHeight - scrollTop - clientHeight < threshold) {
        setPage(prevPage => prevPage + 1);
      }
    };

    scrollContainer.addEventListener('scroll', handleScroll);
    return () => scrollContainer.removeEventListener('scroll', handleScroll);
  }

  function handleFetchedChats() {
    if (!isFetching && data.chats) {
      if (data.chats.length === 0) {
        setHasMore(false);
      } else {
        setAllLoadedChats(prev => {
          const newChats = [...prev];
          data.chats.forEach(chat => {
            if (!newChats.find(c => c.chatId === chat.chatId)) {
              newChats.push(chat);
            }
          });
  
          setfetchedChats(newChats);
  
          return newChats;
        });
      }
    }
  }

  function handleLoaderTypesFetching() {
    if (isFetchingLoaderTypes)
      dispatch(setIsSearching(true));

    if (!isFetchingLoaderTypes && loaderTypesData)
      setLoaderTypesState(loaderTypesData);

    dispatch(setIsSearching(false));
  }

  function handleSettingLoaderType() {
    if (selectedLoaderType) {
      setLoaderTypes(selectedLoaderType);
      dispatch(setIsSearching(true));
    }
  }

  function handleLoaderTypeMutationResult() {
    if (result.isSuccess) {
      dispatch(setIsSearching(false));
    }
  }

  function handleSettingOptionsAndDefaultLoaderType() {
    if (loaderTypes.length > 0) {
      setOptions(loaderTypes.map((loaderType) => ({ 
        label: loaderType.description, 
        value: loaderType.name 
      })));

      let first = loaderTypes[0];
      dispatch(setAppLoaderType({ 
        name: first.name, 
        description: first.description 
      }));
    }
  }

  function handleSearchInputState() {
    const search = document.getElementById("search");
    if (search)
      search.classList[isSearching ? 'add' : 'remove']("disabled");
  }

  function handlePreSelectedLoaderTypes() {
    const preSelectedLoaderTypes = options.length > 0 
      ? loaderTypes.filter((loaderType) => loaderType.is_pre_selected === true) 
      : [];
    if (preSelectedLoaderTypes.length > 0) {
      setLoaderType({ 
        label: preSelectedLoaderTypes[0].description, 
        value: preSelectedLoaderTypes[0].name 
      });
    }
  }
  return (
    <>
      {isLoggedIn && (
        <div>
          <div id="search" className="mb-10">
            <Button 
              className="shadow-none hover:shadow-none" 
              onClick={() => { setChat("") }} 
              text="Ny konversation" 
            />
            <Select
              value={loaderType}
              onChange={handleChange}
              options={options}
              primaryColor={"blue"}
              isMultiple={false}
              searchInputPlaceholder="Sök..."
              isSearchable={false}
              placeholder="Välj källa"
              classNames={{
                menuButton: () => (
                  `flex text-sm px-4 text-gray-500 border border-gray-300 rounded shadow-sm transition-all duration-300 focus:outline-none ${"bg-black hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20"}`
                ),
                menu: "absolute z-10 w-full bg-black shadow-lg border rounded py-1 mt-1.5 text-sm text-white",
                searchBox: "px-4 w-full py-2 pl-8 text-sm bg-black text-white  border border-gray-200 rounded focus:border-gray-200 focus:ring-0 focus:outline-none",
                listItem: () => (
                  `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded hover:bg-neutral-800 ${`text-white bg-black`}`
                ),
                tagItem: () => ("bg-black border text-white rounded-sm flex space-x-1 pl-1"),
                tagItemText: "text-white",
              }}
            />
          </div>

          <div>
            <div className="text-neutral-400 text-sm mb-2">
              Senaste konversationerna
            </div>
            <ul className="w-72">
              {allLoadedChats.map((chat: Chat, index) => (
                <li 
                  key={chat.chatId} 
                  className="mb-2 flex items-center gap-3 w-full"
                  onMouseEnter={() => setHoveredChatId(chat.chatId)}
                  onMouseLeave={() => setHoveredChatId(null)}
                >
                  <Button
                    className="shadow-none hover:shadow-none min-w-[250px]" 
                    onClick={() => { 
                      setChat(chat.chatId);
                      setLoaderType({ 
                        label: chat.loader_type_description, 
                        value: chat.loader_type_name 
                      });
                      setSelectedLoaderType(chat.loader_type_name);
                    }}
                    text={chat.title} 
                    active={chat.chatId === activeChatId}
                  />
                  <div 
                    className={`transition-opacity duration-300 ${
                      hoveredChatId === chat.chatId ? 'opacity-100 visible' : 'opacity-0 invisible'
                    }`}
                  >
                    <NavDropdown chat={chat} />
                  </div>
                </li>
              ))}
            </ul>
            {isFetching && (
              <div className="text-center py-4 text-neutral-400">
                Laddar konversationer...
              </div>
            )}
            {!hasMore && allLoadedChats.length > 0 && (
              <div className="text-center py-4 text-neutral-400">
                Inga fler konversationer att visa
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default PublicNavigation;