import React, { useState } from 'react';
import { useAppDispatch } from '../../../infrastructure/hooks';
import { usePutChatTitleMutation } from "../../../infrastructure/api-slice";
import { setChatTitle } from "../../../domain/chats/chats-slice";
import { BaseModal } from './BaseModal';

interface RenameChatProps {
  currentTitle: string;
  chatId: string;
  onClose: () => void;
}

const RenameChat: React.FC<RenameChatProps> = ({ 
  currentTitle, 
  chatId, 
  onClose 
}) => {
  const dispatch = useAppDispatch();
  const [updateChatTitle] = usePutChatTitleMutation();
  const [newName, setNewName] = useState(currentTitle);

  async function onSave(newName: string) {
    try {
      await updateChatTitle({ 
        chatId, 
        updatedChatTitle: newName 
      });
      dispatch(setChatTitle({ 
        chatId, 
        updatedChatTitle: newName
      }));
      onClose();
    } catch (error) {
      console.error('Failed to update chat title:', error);
    }
  }

  return (
    <BaseModal
      onClose={onClose}
      title="Byt namn på konversation"
      onPrimaryAction={() => onSave(newName)}
    >
      <input
        type="text"
        value={newName}
        onChange={(e) => setNewName(e.target.value)}
        className="w-full p-2 mb-4 text-white bg-neutral-700 rounded-md focus:outline-none"
        placeholder="Ange nytt namn"
      />
    </BaseModal>
  );
};

export default RenameChat;