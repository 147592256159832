
import React, { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../layouts/Layout';
import { Input } from "@material-tailwind/react"
import {
  useLoginUserMutation,
  Error,
} from "../infrastructure/api-slice";

function Login() {
  const [loginUser, response] = useLoginUserMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (response.isSuccess) {
      if (response.data) {
        const form = document.getElementById("form");
        if (form) {
          form.classList.add("hidden");
        }
        const email = (document.getElementById("email") as HTMLInputElement);
        email.value = "";
        const password = (document.getElementById("password") as HTMLInputElement);
        password.value = "";

        localStorage.setItem("user", JSON.stringify(response.data));
        navigate("/");
      }
    } else if (response.isError) {
      const error = response.error as Error;
      const errorMessage = document.getElementById("error_message");
      if (errorMessage) {
        errorMessage.classList.remove("hidden");
        errorMessage.innerHTML = error.data.detail[0].msg;
        document.getElementById("form")?.classList.remove("hidden");
        document.getElementById("loading")?.classList.add("hidden");
        document.getElementById("header")?.classList.remove("hidden");
      }
    }
  }, [navigate, response]);

  const login = useCallback(() => {
    const email = (document.getElementById("email") as HTMLInputElement).value;
    const password = (document.getElementById("password") as HTMLInputElement).value;
    const formData = new FormData();
    formData.append("username", email);
    formData.append("password", password);
    document.getElementById("form")?.classList.add("hidden");
    document.getElementById("loading")?.classList.remove("hidden");
    document.getElementById("header")?.classList.add("hidden");
    loginUser(formData);
  }, [loginUser]);

  return (
    <Layout>
      <div className="flex flex-row justify-center items-center h-screen">
        <div className="flex flex-col w-1/4">
          <h1 id="header" className="text-4xl font-bold text-center mb-4">Logga in</h1>
          <div className="text-black flex flex-col space-y-4 text-white" id="form">

<Input
  label="Email"
  id="email"
  type="text"
  variant="outlined"
  color="white"
/>
<Input
  label="Lösenord"
  id="password"
  type="password"
  variant="outlined"
  color="white"
/>
            <button className="bg-blue-500 text-white p-2 rounded-md text-white bg-neutral-700 hover:border-neutral-400 hover:text-neutral-400 border-white border-2 shadow-none hover:shadow-none " onClick={login} >Logga in</button>
          </div>
          <div id="error_message" className="text-center hidden"></div>
          <div id="loading" className="flex items-center justify-center hidden">
            <div className="flex items-center space-x-2 text-sm text-gray-500">
              <svg
              className="w-6 h-6 animate-spin text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
                <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
                <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8v8h8a8 8 0 01-8 8v-8H4z"
              ></path>
              </svg>
              <span>Laddar...</span>
            </div>
          </div>
        </div>
      </div>
      </Layout>
  );
}

export default Login;
