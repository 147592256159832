/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import {
  useFetchAnswerQuery,
  useFetchChatQuery,
  useDownloadFileMutation,
  Answer,
  Chat,
  useGetProfileQuery
} from "../../infrastructure/api-slice";
import { useAppDispatch, useAppSelector } from "../../infrastructure/hooks";
import { setActiveChatId } from "../../domain/chats/active-chat-slice";
import { setChats } from "../../domain/chats/chats-slice";
import { Button } from "@material-tailwind/react";
import { setIsSearching } from "../../domain/search/search-slice";
import { checkLoginAndRedirect } from '../../utils/authUtils';
import Spinner from "../../UI/Atoms/Spinner/Spinner";

function Home() {
  const dispatch = useAppDispatch();
  const topChats = useAppSelector(state => state.chats.chats)
  const loaderType = useAppSelector(state => state.loaderType)
  const isSearching = useAppSelector(state => state.search.isSearching)
  const activeChatId = useAppSelector((state) => state.activeChat.id)
  const [skip, setSkip] = useState(true)
  const [url, setUrl] = useState("")
  const [answers, setAnswers] = useState<Answer[]>([]);
  const [question, setQuestion] = useState<string>("");
  const [newChatId, setNewChatId] = useState<string>("");
  const { data: answerItem = {} as Answer, isFetching, error } = useFetchAnswerQuery({ question, chatId:activeChatId, loader_type_name: loaderType.name, loader_type_description: loaderType.description }, { skip });
  const { data: activeChat = {} as Chat, isFetching: isFetchingChat } = useFetchChatQuery(activeChatId, {
    skip: !activeChatId,
    refetchOnMountOrArgChange: true
  });
  const ref = useRef<HTMLDivElement>(null);
  const [downloadFile] = useDownloadFileMutation();
  const { data: profileData, isFetching: isFetchingProfile, isUninitialized } = useGetProfileQuery();
  const [hasRun, setHasRun] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (count === 3) {
      // This will cause a rendering error
      throw new Error("This is a test error");
    }
  }, [count]);
  useEffect(() => {
    if (isFetchingProfile || !hasRun) {
      setHasRun(true);
      return;
    }
    checkLoginAndRedirect(profileData);

  }, [profileData, isFetchingProfile, isUninitialized, hasRun]);

  useEffect(() => {
    if (url !== "") {
      downloadFile(url);
      setUrl("")
    }
  }, [downloadFile, url]);

  useEffect(() => {
    if (newChatId === "") return

    if (newChatId !== "")
      dispatch(setActiveChatId(newChatId))
      addNewChat()
    
    return () => {
      setNewChatId("")
    }
  }, [dispatch, newChatId, activeChat.title]);

  useEffect(() => {
    if (activeChatId === "")
      setAnswers([]);
  }, [activeChatId]);

  useEffect(() => {
    if (isFetchingChat || !activeChat.title) return;

    document.title = ""

    if (activeChatId === "")
      return

    document.title = activeChat.title
    
    setAnswers(activeChat.conversation)

  }, [isFetchingChat, activeChatId]);

  useEffect(() => {
    if (answers.length) {
      ref.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
      });
    }
  }, [answers.length]);

  useEffect(() => {
    if (isFetching || skip || question === "" || !answerItem.answer) return;
    setSkip(true)
    dispatch(setIsSearching(false))

    if (error) {
      if ('status' in error && error.status === 401) {
        window.location.href = "/login";
      }
      return
    }
    answers[answers.length - 1] = answerItem

    if (activeChatId !== answerItem.chatId) {
      setNewChatId(answerItem.chatId)
    }
    
    setQuestion("")
  }, [isFetching, skip])

  useEffect(() => {
    const input = document.getElementById("input");
    const search = document.getElementById("search");
    const searchContainer = document.getElementById("searchContainer");
    input?.setAttribute("contenteditable", (!isSearching).toString())
    if (isSearching === true) {
      search?.setAttribute("disabled", "true")
      searchContainer?.classList.add("disabled");
    } else {
      search?.removeAttribute("disabled")
      searchContainer?.classList.remove("disabled");
    }

  }, [isSearching])

  const addNewChat = () => { 
    dispatch(setChats([{ chatId: answerItem.chatId, title: activeChat.title, 
      loader_type_name: loaderType.name, loader_type_description: loaderType.description } as Chat,
      ...topChats.filter((chat: Chat) => chat.chatId !== answerItem.chatId)]))
  }

  const ask = () => {
    const input = document.getElementById("input")
    if (!question) return
    if (input) {
      input.innerText = ""
    }
    setAnswers([...answers, { question: decodeURI(question) } as Answer]);
    setSkip(false)
    dispatch(setIsSearching(true))
  }


  const handleChange = (e: any) => {
    setQuestion(encodeURI(e.target.innerText))
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        document.execCommand('insertLineBreak')
      } else {
        ask()
      }
      e.preventDefault()
    }
  }

  const download = (e: any, url: string) => {
    setUrl(url)
    e.preventDefault();

  }
  return (
    <div className="min-h-screen flex flex-col">
      {/* Chat History Section */}
      <div id="active-chat-history" className="flex-grow w-full flex justify-center mt-10 break-words">
        <div className="w-1/2 max-h-[90%] ">
          {answers.map((answer: Answer, index) => (
            <div key={index}>
              <div
                className={`pb-5 whitespace-pre-wrap transition-opacity ease-in-out delay-150 duration-1000 ${
                  !answer.question ? 'opacity-0' : 'opacity-100'
                }`}
              >
                <span className="font-black">Du:</span> {answer.question}
              </div>
              <div
                className={`pb-5 whitespace-pre-wrap transition-opacity ease-in-out delay-150 duration-1000 ${
                  !answer.answer ? 'opacity-0' : 'opacity-100'
                }`}
              >
                <span className="font-black">Gpt:</span> {answer.answer}
              </div>
              <div
                className={`pb-5 whitespace-pre-wrap transition-opacity ease-in-out delay-150 duration-1000 ${
                  !answer.source ? 'opacity-0' : 'opacity-100'
                }`}
              >
                <span className="font-black">Källa: </span>
                <a
                  href="#"
                  onClick={(e) => {
                    download(e, answer.source);
                  }}
                >
                  {answer.source}
                </a>
              </div>
            </div>
          ))}
          <div ref={ref}></div>
        </div>
      </div>

      {isSearching && <Spinner />}
      <div
        id="searchContainer"
        className="sticky bottom-0 flex justify-center w-full py-4 bg-neutral-700"
      >
        <div
          id="input"
          className="p-1 mr-2 w-96 text-white border-2 border-white rounded whitespace-pre-wrap"
          contentEditable="true"
          onInput={handleChange}
          onKeyDown={handleKeyDown}
        ></div>
        <Button
          id="search"
          size="sm"
          className="pb-2 rounded h-10 w-22 text-white bg-neutral-700 hover:border-neutral-400 hover:text-neutral-400 border-white border-2 shadow-none hover:shadow-none"
          onClick={ask}
        >
          Skicka
        </Button>
      </div>
    </div>
  );
  
}

export default Home;
