
import React, { useState, useEffect } from 'react';
import Layout from '../layouts/Layout';
import {
  useGetProfileQuery,
  useGetCustomerByIdQuery,
  User,
  Loader
} from "../infrastructure/api-slice";

import Select from "react-tailwindcss-select";
import { SelectValue } from "react-tailwindcss-select/dist/components/type";

function DataSources() {
  const { data: profile = {} as User, isFetching } = useGetProfileQuery();
  const { data: customer = {} as any, isFetching: isFetchingCustomer } = useGetCustomerByIdQuery(profile.customer_id, { skip: !profile.customer_id });
  const [loader, setLoader] = useState<Loader>();
  const [loaderType, setLoaderType] = useState<SelectValue>(null);
  useEffect(() => {
    if (!isFetching) {
      console.log(profile);
      if (!profile.is_superuser) {
        // navigate("/login");
      }
    }
  }, [isFetching, profile]);

  useEffect(() => {
    if (!isFetchingCustomer) {
      console.log(customer);
    }
  }, [customer, isFetchingCustomer]);


  const handleChange = (value: any) => {
    if (!value) return;
    setLoader(customer.loaders.find((loader: any) => loader.folder === value.value));
    setLoaderType(value);
  }

  useEffect(() => {
    if (loader) {
      console.log(loader);
    }
  }, [loader]);

  const saveLoader = () => {
    const folder = (document.getElementById("folder") as HTMLInputElement).value;
    const allowed_users = (document.getElementById("allowed_users") as HTMLInputElement).value;
    const amount_of_similar_documents = (document.getElementById("amount_of_similar_documents") as HTMLInputElement).value;
    const chunk_overlap = (document.getElementById("chunk_overlap") as HTMLInputElement).value;
    const chunk_size = (document.getElementById("chunk_size") as HTMLInputElement).value;
    console.log(folder, allowed_users, amount_of_similar_documents, chunk_overlap, chunk_size);
  }

  return (
    <Layout>
      <div className="flex flex-row justify-center items-center h-screen">
        <div className="flex flex-col w-1/3">
          <h1 className="text-4xl font-bold text-center">Administration av datakällor för {customer.name}</h1>
          <div className="flex flex-col mt-5">
            { customer.loaders && (
            <div className="flex flex-row justify-between">
               {customer.loaders.length > 0 && (
               <Select
                  value={loaderType}
                  onChange={handleChange}
                  options={customer.loaders.map((loader: any ) => ({ label: loader.folder, value: loader.folder }))}
                  primaryColor={"blue"}
                  isMultiple={false}
                  searchInputPlaceholder="Sök..."
                  isSearchable={false}
                  placeholder="Välj källa"
                  classNames={{
                    menuButton: () => (
                      `flex text-sm text-gray-500 border border-gray-300 rounded shadow-sm transition-all duration-300 focus:outline-none ${"bg-black hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20"
                      }`
                    ),
                    menu: "absolute z-10 w-full bg-black shadow-lg border rounded py-1 mt-1.5 text-sm text-white",
                    searchBox: "w-full py-2 pl-8 text-sm bg-black text-white  border border-gray-200 rounded focus:border-gray-200 focus:ring-0 focus:outline-none",
                    listItem: () => (
                      `block transition duration-200 px-2 py-2 cursor-pointer select-none truncate rounded hover:bg-neutral-800 ${`text-white bg-black`
                      }`
                    ),
                    tagItem: () => ("bg-neutral-700 border text-black rounded-sm flex space-x-1 pl-1"),
                    tagItemText: "text-white",
                  }}
                />
                )}
                
            </div>
            )}
            {loader && ( 
            <div className="flex flex-col mt-5">
              <div className="flex flex-row justify-between">
                <form className="flex flex-col space-y-4 text-black" id="form">
                    <div className="flex flex-row justify-between">
                      <label htmlFor="folder" className='text-white'>Folder</label>
                      <input type="text" id="folder" className="p-2 border-b-2 border-gray-300 focus:outline-none" value={loader.folder} />
                    </div>
                    <div className="flex flex-row justify-between">
                      <label htmlFor="allowed_users" className='text-white'>Allowed Users</label>
                      <input type="text" id="allowed_users" className="p-2 border-b-2 border-gray-300 focus:outline-none" value={loader.allowed_users} />
                    </div>
                    <div className="flex flex-row justify-between">
                      <label htmlFor="amount_of_similar_documents" className='text-white pr-4'>Amount of Similar Documents</label>
                      <input type="text" id="amount_of_similar_documents" className="p-2 border-b-2 border-gray-300 focus:outline-none" value={loader.amount_of_similar_documents} />
                    </div>
                    <div className="flex flex-row justify-between">
                      <label htmlFor="chunk_overlap" className='text-white m-x-4'>Chunk Overlap</label>
                      <input type="text" id="chunk_overlap" className="p-2 border-b-2 border-gray-300 focus:outline-none" value={loader.chunk_overlap} />
                    </div>
                    <div className="flex flex-row justify-between">
                      <label htmlFor="chunk_size" className='text-white'>Chunk Size</label>
                      <input type="text" id="chunk_size" className="p-2 border-b-2 border-gray-300 focus:outline-none" value={loader.chunk_size} />
                    </div>
                    <div className="flex flex-row justify-between">
                      <button className="bg-blue-500 text-white p-2 rounded-md" onClick={saveLoader} >Spara</button>
                    </div>
                </form>
              </div>
            </div>
            )}
          </div>  
        </div>
      </div>
    </Layout>
  );
}

export default DataSources;
