import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import JSZip from "jszip";

import {
  BaseQueryApi,
  FetchBaseQueryError,
  FetchArgs,
  FetchBaseQueryMeta,
} from "@reduxjs/toolkit/query";
import { QueryReturnValue } from "@reduxjs/toolkit/dist/query/baseQueryTypes";
import { MaybePromise } from "@reduxjs/toolkit/dist/query/tsHelpers";

type UploadFileResponse = { data: string };
type UploadFileError = FetchBaseQueryError;

//const API_KEY = 'live_7sIWImsRZbGALMh7zVZfehqB23IzJ5mjJ0zPOrPn0bzHvCuTHn88lPz9qVnnVBGz';
const baseUrl = process.env.REACT_APP_API_URL;
export interface Answer {
  chatId: string;
  question: string;
  answer: string;
  source: string;
}

interface Question {
  chatId: string;
  question: string;
  loader_type_name: string;
  loader_type_description: string;
}

export interface User {
  id: string;
  email: string;
  is_active: boolean;
  is_superuser: boolean;
  customer_id: string;
  password: string;
}

export interface Error {
  status: string;
  data: Data;
}

export interface Data {
  detail: Detail[];
}
export interface Detail {
  msg: string;
}
export interface LoginResponse {
  access_token: string;
  token_type: string;
}
export interface Chats {
  chats: Chat[];
  chat_count: number;
}

export interface Chat {
  userId: string;
  title: string;
  chatId: string;
  conversation: Answer[];
  loader_type_name: string;
  loader_type_description: string;
}

export interface LoaderType {
  name: string;
  description: string;
  is_pre_selected: boolean;
}

export interface Loader {
  folder: string;
  allowed_users: string[];
  amount_of_similar_documents: number;
  chunk_overlap: number;
  chunk_size: number;
  prompt: string;
}

export interface Customer {
  id: string;
  name: string;
  description: string;
  loaders: Loader[];
}

export interface SupportData {
  llm_name: string;
}

type FetchWithTimeoutOptions = RequestInit & {
  timeout?: number; // Make timeout an optional property
};

const fetchWithTimeout = async (
  resource: string,
  options: FetchWithTimeoutOptions = {},
): Promise<Response> => {
  const { timeout = 300000, ...restOptions } = options; // Default timeout is 5 minutes

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  try {
    const response = await fetch(resource, {
      ...restOptions, // Pass the rest of the options to fetch
      signal: controller.signal, // Attach the AbortController signal
    });

    clearTimeout(id); // Clear the timeout after the fetch completes

    return response;
  } catch (error) {
    clearTimeout(id); // Ensure the timeout is cleared in case of an error
    if (error instanceof DOMException && error.name === "AbortError") {
      throw new Error("Request timed out");
    }
    throw error; // Re-throw any other errors
  }
};

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
    fetchFn: fetchWithTimeout,
    prepareHeaders: (headers) => {
      headers.set(
        "Authorization",
        `Bearer ${JSON.parse(localStorage.getItem("user") || "{}")?.access_token}`,
      );
      return headers;
    },
  }),
  endpoints(builder) {
    return {
      fetchAnswer: builder.query<Answer, Question>({
        query: ({
          question,
          chatId,
          loader_type_name,
          loader_type_description,
        }) => ({
          url: `/answer/?question=${question}&chatId=${chatId}&loader_type_name=${loader_type_name}&loader_type_description=${loader_type_description}`,
          method: "GET",
          mode: "cors",
        }),
      }),
      fetchChats: builder.query<
        Chats,
        { amount: number; page: number; sessionId: number }
      >({
        query: ({ amount, page, sessionId }) => ({
          url: `/answer/top/?amount=${amount}&page=${page}&sessionId=${sessionId}`,
          method: "GET",
          mode: "cors",
        }),
      }),
      fetchChat: builder.query<Chat, string>({
        query: (chatId) => ({
          url: `/answer/one/?chatId=${chatId}`,
          method: "GET",
          mode: "cors",
        }),
      }),
      putChatTitle: builder.mutation<string, { chatId: string; updatedChatTitle: string }>({
        query: ({ chatId, updatedChatTitle }) => ({
          url: `/answer/update_chat_title/?chatId=${chatId}&updated_chat_title=${updatedChatTitle}`,
          method: "PUT",
          mode: "cors",
        }),
      }),
      deleteChat: builder.mutation<string, string>({
        query: (chatId) => ({
          url: `/answer/delete_chat/?chatId=${chatId}`,
          method: "DELETE",
          mode: "cors",
        }),
      }),
      fetchFile: builder.query<{ data: string }, string>({
        query: (url) => ({
          url: `/answer/download/?path=${url}`,
          method: "GET",
          mode: "cors",
        }),
      }),
      getLoaderTypes: builder.query<LoaderType[], void>({
        query: () => ({
          url: `/answer/loader_types/`,
          method: "GET",
          mode: "cors",
        }),
      }),
      setLoaderType: builder.mutation<string[], string>({
        query: (type) => ({
          url: `/answer/loader_type/?loader_type=${type}`,
          method: "POST",
          body: type,
        }),
      }),
      registerUser: builder.mutation<
        User,
        { email: string; password: string; customerId: string }
      >({
        query: ({ email, password, customerId }) => ({
          url: `/auth/register/?email=${email}&password=${password}&customer_id=${customerId}`,
          method: "POST",
          body: { email, password, customer_id: customerId },
        }),
      }),
      saveLoader: builder.mutation<
        Loader,
        {
          folder: string;
          allowed_users: string;
          amount_of_similar_documents: number;
          chunk_overlap: number;
          chunk_size: number;
        }
      >({
        query: ({
          folder,
          allowed_users,
          amount_of_similar_documents,
          chunk_overlap,
          chunk_size,
        }) => ({
          url: `/customer/loader/?folder=${folder}&allowed_users=${allowed_users}&amount_of_similar_documents=${amount_of_similar_documents}&chunk_overlap=${chunk_overlap}&chunk_size=${chunk_size}`,
          method: "POST",
          body: {
            folder,
            allowed_users,
            amount_of_similar_documents,
            chunk_overlap,
            chunk_size,
          },
        }),
      }),
      loginUser: builder.mutation<LoginResponse, FormData>({
        query: (formData) => ({
          url: `/auth/jwt/login/`,
          method: "POST",
          body: formData,
          mode: "cors",
        }),
      }),
      getProfile: builder.query<User, void>({
        query: () => ({
          url: `/users/me/`,
          method: "GET",
          mode: "cors",
        }),
      }),
      getCustomer: builder.query<Customer, string>({
        query: (name) => ({
          url: `/customer/?name=${name}`,
          method: "GET",
          mode: "cors",
        }),
      }),
      getCustomerById: builder.query<Customer, string>({
        query: (id) => ({
          url: `/customer/by_id/?id=${id}`,
          method: "GET",
          mode: "cors",
        }),
      }),
      getFiles: builder.query<
        { data: string[] },
        { customer_name: string; folder: string }
      >({
        query: ({ customer_name, folder }) => ({
          url: `/customer/files/?customer_name=${customer_name}&folder=${folder}`,
          method: "GET",
          mode: "cors",
        }),
      }),
      putCustomer: builder.mutation<Customer, Customer>({
        query: (customer) => ({
          url: `/customer/update/?id=${customer.id}`,
          method: "PUT",
          body: customer,
          mode: "cors",
        }),
      }),
      reindexLoader: builder.query<string, string>({
        query: (loader) => ({
          url: `/customer/reindex/?loader=${loader}`,
          method: "GET",
          mode: "cors",
        }),
      }),
      uploadFileZip: builder.mutation<
        UploadFileResponse,
        { customer_name: string; folder: string; files: File[] }
      >({
        queryFn: async ({ customer_name, folder, files }) => {
          try {
            // Step 1: Create a JSZip instance
            const zip = new JSZip();
            console.log(files);

            // Step 2: Add each file to the zip archive
            files.forEach((file) => {
              console.log(file);
              zip.file(file.name, file);
            });
            console.log("zip");
            console.log(zip);
            // Step 3: Generate the zip file as a Blob
            const zipBlob = await zip.generateAsync({ type: "blob" });
            console.log("zipBlob");
            console.log(zipBlob);
            // Step 4: Create FormData and append the zip file
            const formData = new FormData();
            formData.append("file", zipBlob, "archive.zip");
            formData.append("customer_name", customer_name);
            formData.append("folder", folder);

            // Step 5: Use fetch to upload the FormData
            const response = await fetch(`/api/customer/upload_zip`, {
              method: "POST",
              body: formData,
            });

            if (!response.ok) {
              return {
                error: {
                  status: response.status,
                  data: await response.text(),
                },
              };
            }

            return { data: { data: "Upload successful" } };
          } catch (error: any) {
            return {
              error: {
                status: "FETCH_ERROR",
                error: error.message,
              },
            };
          }
        },
      }),
      uploadFileChunked: builder.mutation<
        UploadFileResponse,
        { customer_name: string; folder: string; files: File[] }
      >({
        queryFn: async (
          { customer_name, folder, files },
          _api: BaseQueryApi,
          _extraOptions: {},
          _baseQuery: (
            arg: string | FetchArgs,
          ) => MaybePromise<
            QueryReturnValue<unknown, FetchBaseQueryError, FetchBaseQueryMeta>
          >,
        ): Promise<
          QueryReturnValue<UploadFileResponse, FetchBaseQueryError>
        > => {
          const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB chunk size

          try {
            for (const file of files) {
              const totalChunks = Math.ceil(file.size / CHUNK_SIZE);

              for (
                let chunkNumber = 0;
                chunkNumber < totalChunks;
                chunkNumber++
              ) {
                const start = chunkNumber * CHUNK_SIZE;
                const end = Math.min(start + CHUNK_SIZE, file.size);
                const chunk = file.slice(start, end);

                const formData = new FormData();
                formData.append("chunk", chunk);
                formData.append("chunkNumber", chunkNumber.toString());
                formData.append("totalChunks", totalChunks.toString());
                formData.append("customer_name", customer_name);
                formData.append("folder", folder);
                formData.append("fileName", file.name);

                const response = await fetch(`/api/customer/upload_chunk/`, {
                  method: "POST",
                  body: formData,
                });

                if (!response.ok) {
                  // If the response is not OK, return an error that matches FetchBaseQueryError
                  return {
                    error: {
                      status: response.status,
                      data: await response.text(), // or JSON depending on your backend response
                    },
                  };
                }
              }
            }
            // Return the success response
            return { data: { data: "Upload successful" } };
          } catch (error: any) {
            // Catch and return the error in a compatible format
            return {
              error: {
                status: "FETCH_ERROR",
                error: error.message,
              },
            };
          }
        },
      }),
      uploadFile: builder.mutation<
        { data: string },
        { customer_name: string; folder: string; files: File[] }
      >({
        query: ({ customer_name, folder, files }) => {
          const formData = new FormData();
          for (let i = 0; i < files.length; i++) {
            formData.append("files", files[i]);
          }
          return {
            url: `/customer/upload/?customer_name=${customer_name}&folder=${folder}`,
            method: "POST",
            body: formData,
            mode: "cors",
          };
        },
      }),
      deleteFile: builder.mutation<{ data: string }, string>({
        query: (path) => ({
          url: `/customer/file/?file_path=${path}`,
          method: "DELETE",
          mode: "cors",
        }),
      }),
      downloadFile: builder.mutation({
        queryFn: async (path, _) => {
          const response = await fetch(
            `${baseUrl}/answer/download/?path=${path}`,
          );
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const blob = await response.blob();
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", path.split("/").pop());
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          return { data: null };
        },
      }),

      getCustomers: builder.query<Customer[], void>({
        query: () => ({
          url: `/customer/`,
          method: "GET",
          mode: "cors",
        }),
      }),
      getUsers: builder.query<User[], void>({
        query: () => ({
          url: `/user/all`,
          method: "GET",
          mode: "cors",
        }),
      }),
      getUserById: builder.query<User, string>({
        query: (id) => ({
          url: `/user/by_id/?id=${id}`,
          method: "GET",
          mode: "cors",
        }),
      }),
      postUser: builder.mutation<User, User>({
        query: (user) => ({
          url: `/user/`,
          method: "POST",
          body: JSON.stringify(user), // Ensure the body is stringified
          mode: "cors",
          headers: {
            "Content-Type": "application/json", // Set the Content-Type header
          },
        }),
      }),
      putUser: builder.mutation<User, User>({
        query: (user) => ({
          url: `/user/${user.id}`,
          method: "PUT",
          body: JSON.stringify(user),
          mode: "cors",
          headers: {
            "Content-Type": "application/json", // Set the Content-Type header
          },
        }),
      }),
      deleteUser: builder.mutation<User, string>({
        query: (id) => ({
          url: `/user/${id}`,
          method: "DELETE",
          mode: "cors",
        }),
      }),
      getSupportData: builder.query<SupportData, void>({
        query: () => ({
          url: `/user/support`,
          method: "GET",
          mode: "cors",
        }),
      }),

    };
  },
});

export const {
  useFetchAnswerQuery,
  useFetchChatQuery,
  useFetchChatsQuery,
  useFetchFileQuery,
  useGetLoaderTypesQuery,
  useSetLoaderTypeMutation,
  useDownloadFileMutation,
  useRegisterUserMutation,
  useLoginUserMutation,
  useGetProfileQuery,
  useGetCustomerQuery,
  useGetCustomerByIdQuery,
  usePutCustomerMutation,
  useGetFilesQuery,
  useDeleteFileMutation,
  useUploadFileMutation,
  useGetCustomersQuery,
  useGetUsersQuery,
  useGetUserByIdQuery,
  usePostUserMutation,
  usePutUserMutation,
  useDeleteUserMutation,
  useReindexLoaderQuery,
  useGetSupportDataQuery,
  usePutChatTitleMutation,
  useDeleteChatMutation,
} = apiSlice;
