import React, { useState } from 'react';
import { useAppDispatch } from '../../../infrastructure/hooks';
import { BaseModal } from './BaseModal';
import {useDeleteChatMutation} from "../../../infrastructure/api-slice";
import { deleteChat } from "../../../domain/chats/chats-slice";

interface DeleteChatProps {
  chatId: string;
  chatTitle: string;
  onClose: () => void;
}

const DeleteChat: React.FC<DeleteChatProps> = ({ chatId, chatTitle, onClose }) => {

  const dispatch = useAppDispatch();
  const [deleteChatMutation] = useDeleteChatMutation();

  async function onDelete() {
  try {
    await deleteChatMutation(chatId);
    dispatch(deleteChat(chatId));
    onClose();
    
  } catch (error) {
    console.error('Failed to delete chat:', error);
  }
}

  return <BaseModal
    onClose={onClose}
    title="Är du säker på att du vill ta bort konversationen?"
    primaryButtonText="Ta bort"
    onPrimaryAction={() => onDelete()}
    >
        <div className='text-white font-semibold mb-4'>{chatTitle}</div>
    </BaseModal>
};

export default DeleteChat;