import React, { useState, useEffect } from 'react';
import Layout from '../layouts/Layout';
import {
  useGetProfileQuery,
  useGetUsersQuery,
  useGetUserByIdQuery,
  usePostUserMutation,
  usePutUserMutation,
  useDeleteUserMutation,
  User,
  Customer
} from "../infrastructure/api-slice";
import { Input, Button, Checkbox } from "@material-tailwind/react";
import Select from "react-tailwindcss-select";

function Users() {
  const { data: profileData } = useGetProfileQuery();
  const { data: users = [] as User[], refetch: refetchUsers } = useGetUsersQuery();
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const { data: selectedUser, refetch: refetchSelectedUser } = useGetUserByIdQuery(selectedUserId || "", { skip: !selectedUserId });
  const [user, setUser] = useState<User>({ email: '', is_active: false, is_superuser: false, customer_id: '' } as User);
  const [createMode, setCreateMode] = useState(false);
  const [errors, setErrors] = useState<{ email?: string; password?: string }>({});

  const [createUser] = usePostUserMutation();
  const [updateUser] = usePutUserMutation();
  const [deleteUser] = useDeleteUserMutation();

  // Update user form when selectedUser changes
  useEffect(() => {
    if (selectedUser) {
      setUser(selectedUser);
    }
  }, [selectedUser]);

  // Refetch user data when a new user is selected
  useEffect(() => {
    if (selectedUserId) {
      refetchSelectedUser();
    }
  }, [selectedUserId, refetchSelectedUser]);

  const handleSelectUser = (value: any) => {
    if (!value) return;
    setSelectedUserId(value.value);
    setCreateMode(false);
  };

  const newUser = () => {
    setCreateMode(true);
    setUser({ email: '', is_active: false, is_superuser: false, customer_id: profileData?.customer_id } as User);
    setErrors({});
  };

  const validateUser = () => {
    const newErrors: { email?: string; password?: string } = {};

    // Check if email is filled out
    if (!user.email) {
      newErrors.email = 'Email får inte vara tomt.';
    }

    // Check if password is filled in create mode
    if (createMode && !user.password) {
      newErrors.password = 'Lösenord får inte vara tomt.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const saveUser = async () => {
    if (!validateUser()) return; // Stop if there are validation errors

    if (createMode) {
      await createUser(user);
    } else if (selectedUserId) {
      await updateUser({ ...user, id: selectedUserId });
    }
    refetchUsers();
    setCreateMode(false);
    setErrors({});
  };

  const deleteSelectedUser = async () => {
    if (selectedUserId && window.confirm('Är du säker på att du vill ta bort användaren?')) {
      await deleteUser(selectedUserId);
      refetchUsers();
      setSelectedUserId(null);
      setUser({ email: '', is_active: false, is_superuser: false, customer_id: '', password: '', id: '' } as User);
    }
  };

  return (
    <Layout>
      <div className="flex flex-row justify-center">
        <div className="flex flex-col w-1/2 mt-24">
          <h1 className="text-4xl font-bold text-center">Användare</h1>
          <h2 className="text-3xl text-center mb-4">Hantera användare</h2>

          <div className="mb-8 flex flex-row">
            <div className="mr-4 w-8/12">
              <label className="text-sm text-gray-500">Välj användare</label>
              <Select
              value={selectedUserId ? { label: user.email, value: selectedUserId } : null}
              primaryColor={"blue"}
              onChange={handleSelectUser}
              options={users.map((user) => ({
                label: user.email,
                value: user.id,
              }))}
              classNames={{
                menuButton: () =>
                  `flex text-sm text-white border border-gray-300 rounded shadow-sm transition-all duration-300 focus:outline-none px-4 ${"bg-neutral-700 hover:border-gray-400 focus:border-blue-500 focus:ring focus:ring-blue-500/20"
}`, // Added `px-4` for horizontal padding
                menu: "absolute z-10 w-full bg-neutral-700 shadow-lg border rounded py-1 mt-1.5 text-sm text-white",
                searchBox: "w-full py-2 pl-8 text-sm bg-neutral-700 text-white border border-gray-200 rounded focus:border-gray-200 focus:ring-0 focus:outline-none",
                listItem: () =>
                  `block transition duration-200 px-4 py-2 cursor-pointer select-none truncate rounded hover:bg-neutral-800 ${`text-white bg-neutral-700`
}`, // Added `px-4` for horizontal padding
                tagItem: () => "bg-neutral-700 border text-white rounded-sm flex space-x-1 pl-1",
                tagItemText: "text-white",
              }}
            />            </div>
            <div className="mt-6 ml-9">
              <button className="bg-neutral-700 border-white border hover:border-neutral-400 hover:text-neutral-400 text-white font-bold py-1.5 px-4 rounded" onClick={newUser}>
                Skapa ny användare
              </button>
            </div>
          </div>

          {(createMode || selectedUserId) && (
            <div className="justify-center mt-5">
              <div className="text-white">
                <div className="mb-4">
                  <Input
                    label="Email"
                    type="email"
                    color="white"
                    value={user.email}
                    onChange={(e) => setUser({ ...user, email: e.target.value })}
                    crossOrigin={undefined}
                    error={!!errors.email}
                  />
                  {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                </div>
                <div className="mb-4">
                  <Input
                    label="Lösenord"
                    type="password"
                    color="white"
                    value={user.password || ''}
                    onChange={(e) => setUser({ ...user, password: e.target.value })}
                    crossOrigin={undefined}

    className="border border-gray-300 rounded-md !border-t !border-solid"
                    error={!!errors.password}
                  />
                  {errors.password && <p className="text-red-500 text-sm">{errors.password}</p>}
                </div>
                <div className="mb-4 text-white">
                 <Checkbox
                    label="Admin"
                    checked={user.is_superuser}
                    onChange={(e) => setUser({ ...user, is_superuser: e.target.checked })}
                  />
                </div>
                <div className="mb-4">
                  <button
                    className="bg-neutral-700 border-white border hover:border-neutral-400 hover:text-neutral-400 text-white font-bold py-1.5 px-4 rounded"
                    onClick={saveUser}
                  >
                    Spara
                  </button>
                  {!createMode && selectedUserId && (
                    <button
                      className="ml-4 bg-red-700 border-white border hover:border-neutral-400 hover:text-neutral-400 text-white font-bold py-1.5 px-4 rounded"
                      onClick={deleteSelectedUser}
                    >
                      Ta bort
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Layout>
  );
}

export default Users;
