import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface UserState {
  id: string;
  access_token: string;
  isLoggedIn: boolean | undefined;
}

const initialState: UserState = {
  id: "",
  access_token: "",
  isLoggedIn: undefined
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserId(state: UserState, action: PayloadAction<string>) {
      state.id = action.payload;
    },
    setAccessToken(state: UserState, action: PayloadAction<string>) {
      state.access_token = action.payload;
    },
    setIsLoggedIn(state: UserState, action: PayloadAction<boolean>) {
      state.isLoggedIn = action.payload;
    }
  }
});

export const { setUserId, setAccessToken, setIsLoggedIn } = userSlice.actions;
export default userSlice.reducer;

