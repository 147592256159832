import React from 'react';

const Spinner = () => {
  return (
    <div id="loading" className="flex items-center justify-center">
    <div className="flex items-center space-x-2 text-sm text-gray-500">
      <svg
      className="w-6 h-6 animate-spin text-gray-400"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
        <circle
        className="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        strokeWidth="4"
      ></circle>
        <path
        className="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8v8h8a8 8 0 01-8 8v-8H4z"
      ></path>
      </svg>
      <span>Laddar chat...</span>
    </div>
  </div>
  );
}

export default Spinner;