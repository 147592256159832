
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Chat } from '../../infrastructure/api-slice'

interface ChatState {
  chats: Chat[];
}

const initialState: ChatState = {
  chats: [],
}

export const chatsSlice = createSlice({
  name: 'chats',
  initialState,
  reducers: {
    setChats(state: ChatState, action: PayloadAction<Chat[]>) {
      state.chats = action.payload;
    },

    setChatTitle(state: ChatState, action: PayloadAction<{ chatId: string, updatedChatTitle: string }>) {
      const chat = state.chats.find((chat) => chat.chatId === action.payload.chatId);
      if (chat) {
        chat.title = action.payload.updatedChatTitle;
      }
    },

    deleteChat(state: ChatState, action: PayloadAction<string>) {
      state.chats = state.chats.filter((chat) => chat.chatId !== action.payload);

    }
  }

});

export const { setChats, setChatTitle, deleteChat } = chatsSlice.actions;
export default chatsSlice.reducer;

