import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface ActiveChatState {
  id: string;
}

const initialState: ActiveChatState= {
  id: "",
}

export const chatSlice = createSlice({
  name: 'activeChat',
  initialState,
  reducers: {
    setActiveChatId(state: ActiveChatState, action: PayloadAction<string>) {
      state.id = action.payload;
    }
  }

});

export const { setActiveChatId } = chatSlice.actions;
export default chatSlice.reducer;

