import React from "react";

import Header from "./Header";
import PublicNavigation from "./PublicNavigation";
import AdminNavigation from "./AdminNavigation";
import { useEffect, useState } from "react";
import { useGetProfileQuery } from "../../infrastructure/api-slice";
function Navigation() {
  const { data: profileData, isFetching: isFetchingProfile } = useGetProfileQuery();
  const [isSuperUser, setIsSuperUser] = useState(false);

  useEffect(() => {
    if (isFetchingProfile)
      return;
    if (profileData) {
      setIsSuperUser(profileData.is_superuser);
    }
  }, [profileData, isFetchingProfile])

  return (
    <div className="w-64 mt-10 ml-5">
      <Header />
      {isSuperUser && window.location.pathname.includes("admin") && <AdminNavigation />}
      {!window.location.pathname.includes("admin") && <PublicNavigation />}
    </div>
  );
}

export default Navigation;
