import { Button as B } from "@material-tailwind/react";
import React from "react";

interface ButtonProps {
    onClick: () => void
    text: string
    active?: boolean
    className?: string
}

function Button(props: ButtonProps) {    
    return (
    <B className={`border-0 hover:bg-neutral-800 p-2 text-left normal-case text-md w-full ${props.className} ${props.active ? "bg-neutral-800" : "bg-transparent"}`} 
            onClick={props.onClick}> {props.text}</B>
      );
}
  
export default Button;
