import React, { useEffect, useState } from "react";
import { useGetProfileQuery } from "../../infrastructure/api-slice";
import { setUserId, setIsLoggedIn } from "../../domain/users/user-slice";
import { setActiveChatId } from "../../domain/chats/active-chat-slice";
import { setChats } from "../../domain/chats/chats-slice";
import { setIsSearching } from "../../domain/search/search-slice";
import Button from "../Atoms/Buttons/Button";
import { useAppDispatch, useAppSelector } from "../../infrastructure/hooks";

function Header() {

  const { data: profileData, isFetching: isFetchingProfile } = useGetProfileQuery();
  const isLoggedIn = useAppSelector(state => state.user.isLoggedIn)
  const [profileEmail, setProfileEmail] = useState<string | undefined>(undefined);
  const [isSuperUser, setIsSuperUser] = useState(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (isFetchingProfile)
      return;
    if (profileData) {
      setProfileEmail(profileData.email);
      dispatch(setUserId(profileData.id));
      setIsSuperUser(profileData.is_superuser);
    }
  }, [profileData, isFetchingProfile, dispatch])

  useEffect(() => {
    dispatch(setIsLoggedIn(profileEmail ? true : false));
    if (profileEmail === undefined)
      return;
    if (!profileEmail && window.location.pathname !== "/login") {
      handleLogout();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, profileEmail])


  const handleLogout = () => {
    dispatch(setUserId(""));
    dispatch(setChats([]));
    dispatch(setActiveChatId(""));
    dispatch(setIsSearching(false));
    localStorage.removeItem("user");
    window.location.href = "/login";
  }

  return (
    <header className="mb-5">
      <img
        src="/logo.png"
        className="h-auto max-w-sm fa-solid fa-user"
        alt="Swesharp"
        width={200}
        height={200}
      />
      <div>
        {profileEmail && <div className="text-white p-2 shadow-none hover:shadow-none">{profileEmail}</div>}
        {isLoggedIn && <Button className="shadow-none hover:shadow-none" onClick={handleLogout} text="Logga ut" />}
        {isSuperUser && !window.location.pathname.includes("admin") && <Button className="shadow-none hover:shadow-none" onClick={() => window.location.href = "/admin/loaders"} text="Administration" />}
      </div>
    </header>
  );
}

export default Header;

