import React from "react";
import Layout from "../layouts/Layout";
import Home from "../UI/Organisms/Home";

import ErrorBoundary from '../infrastructure/ErrorBoundary';

function Root() {
  return (
    <Layout>
      <ErrorBoundary>
        <Home />
      </ErrorBoundary>
    </Layout>
  );
}

export default Root;
