
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

interface SearchState {
  isSearching: Boolean;
}

const initialState: SearchState = {
  isSearching: false
}

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setIsSearching(state: SearchState, action: PayloadAction<Boolean>) {
      state.isSearching = action.payload;
    }
  }

});

export const { setIsSearching } = searchSlice.actions;
export default searchSlice.reducer;

