import React from "react";
import Navigation from "../UI/Molecules/Navigation";

type ContainerProps = {
  children: React.ReactNode;
};

const Layout = (props: ContainerProps) => {
  return (
    <div className="flex flex-row">
      <div id="nav-parent" className="bg-black sticky top-0 h-screen hidden md:block overflow-y-auto min-w-[340px]">
        <Navigation />
      </div>
      <div className="grow min-h-screen bg-neutral-700 text-white break-words">
        {props.children}
      </div>
    </div>
  );
};

export default Layout;
