import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface LoaderTypeState {
  name: string;
  description: string;
}

const initialState: LoaderTypeState= {
  name: "",
  description: ""
}

export const loaderTypeSlice = createSlice({
  name: 'loaderType',
  initialState,
  reducers: {
    setLoaderType(state: LoaderTypeState, action: PayloadAction<LoaderTypeState>) {
      state.name = action.payload.name;
      state.description = action.payload.description;
    }
  }

});

export const { setLoaderType } = loaderTypeSlice.actions;
export default loaderTypeSlice.reducer;

