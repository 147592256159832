import { configureStore } from '@reduxjs/toolkit'
import { apiSlice } from '../infrastructure/api-slice'
import chatReducer from '../domain/chats/active-chat-slice'
import loaderTypeReducer from '../domain/loader_types/loader_type-slice'
import userReducer from '../domain/users/user-slice'
import chatsReducer from '../domain/chats/chats-slice'
import searchReducer from '../domain/search/search-slice'

export const store = configureStore({
  reducer: { 
    [apiSlice.reducerPath]: apiSlice.reducer,
    activeChat: chatReducer,
    chats: chatsReducer, 
    loaderType: loaderTypeReducer, 
    search: searchReducer,
    user: userReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware().concat(apiSlice.middleware)
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
