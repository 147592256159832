import React, { useState, useEffect, useRef } from 'react';
import RenameChat from '../Modal/RenameChat';
import DeleteChat from '../Modal/DeleteChat';
import { Chat } from '../../../infrastructure/api-slice';

interface NavDropdownProps {
  chat : Chat;
}

const NavDropdown: React.FC<NavDropdownProps> = ({ chat }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [showRenameChatModal, setShowRenameChatModal] = useState(false);
  const [showDeleteChatModal, setShowDeleteChatModal] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  // Close dropdown if clicked outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative inline-block h-full text-left font-sans font-bold " ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className="p-1 text-white rounded-lg transition-all hover:bg-neutral-800"
      >
        •••
      </button>

      <div
        className={`absolute right-0 z-10 mt-2 w-28 bg-neutral-800 rounded-md shadow-lg 
            transition-all duration-200 transform
            ${isOpen ? 'opacity-100 scale-100' : 'opacity-0 scale-95 pointer-events-none'}`}
      >
        <div className="p-1">
          <button
            onClick={() => {
              setIsOpen(false);
              setShowRenameChatModal(true);
            }}
            className="w-full text-left px-4 py-2 text-sm text-white hover:bg-neutral-700 rounded-md"
          >
            Redigera
          </button>
          <button
            onClick={() => {
              setShowDeleteChatModal(true);
              setIsOpen(false);
            }}
            className="w-full text-left px-4 py-2 text-sm text-red-500 hover:bg-neutral-700 rounded-md"
          >
            Ta bort
          </button>
        </div>
      </div>
      {showRenameChatModal && (
        <RenameChat
          currentTitle={chat.title}
          chatId={chat.chatId}
          onClose={() => setShowRenameChatModal(false)}
        />
      )}
      {showDeleteChatModal && (
        <DeleteChat
          chatId={chat.chatId}
          chatTitle={chat.title}
          onClose={() => setShowDeleteChatModal(false)}
        />
      )}
    </div>
  );
};

export default NavDropdown;
