
export const checkLoginAndRedirect = (profileData) => {

    const user = localStorage.getItem("user");
    const access_token = user ? JSON.parse(user as string).access_token : "";

    if ((!access_token || access_token === "" || (!profileData || profileData.id === ""))) {
        window.location.href = "/login";
    }

    return access_token;
};
