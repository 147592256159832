import React from "react";
import Layout from "../layouts/Layout";
import { useGetSupportDataQuery } from "../infrastructure/api-slice";

function Support() {
    const { data: supportData } = useGetSupportDataQuery();
    return (
        <Layout>
            <div className="flex flex-row justify-center">
                <div className="flex flex-col w-1/2 mt-24">
                    <h1 className="text-4xl font-bold text-center mb-10">Support</h1>
                    <div className="flex gap-8 flex-col">
                        <div className="text-center">
                            Språkmodell: {supportData?.llm_name}
                        </div>
                        <div className="text-center">
                            Support: aisupport@swesharp.zendesk.com
                        </div>
                        <div className="text-center">
                            Telefon: 0243-130 20
                        </div>
                        <div className="text-center">
                            Swesharp Consulting AB
                        </div>
                        <div className="text-center ">
                            <a href="https://www.swesharp.se/">www.swesharp.se</a>
                        </div>
                    </div>

                </div>
            </div>
        </Layout>
        
    );
}

export default Support;