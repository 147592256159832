import React, { useState, useEffect } from 'react';
import Layout from '../layouts/Layout';
import {
  useRegisterUserMutation,
  useGetCustomerQuery,
  Customer,
  Error,
} from "../infrastructure/api-slice";

function Register() {
  const [registerUser, response] = useRegisterUserMutation();
  const { data: customer = {} as Customer, isFetching } = useGetCustomerQuery("Kund 1");
  const [customerId, setCustomerId] = useState("")

  useEffect(() => {
    if (!isFetching) {
      setCustomerId(customer.id);
    }
  }, [customer.id, isFetching]);

  useEffect(() => {
    if (response.isSuccess) {
      if (response.data) {
        const registeredMessage = document.getElementById("registered_message");
        if (registeredMessage) {
          registeredMessage.classList.remove("hidden");
        }
        const form = document.getElementById("form");
        if (form) {
          form.classList.add("hidden");
        }
        const email = (document.getElementById("email") as HTMLInputElement);
        email.value = "";
        const password = (document.getElementById("password") as HTMLInputElement);
        password.value = "";
      }
    } else if (response.isError) {
      const error = response.error as Error;
      const errorMessage = document.getElementById("error_message");
      if (errorMessage) {
        errorMessage.classList.remove("hidden");
        errorMessage.innerHTML = error.data.detail[0].msg;
      }
    }
  }, [response]);

  const save = () => {
    const email = (document.getElementById("email") as HTMLInputElement).value;
    const password = (document.getElementById("password") as HTMLInputElement).value;
    const confirm_password = (document.getElementById("confirm_password") as HTMLInputElement).value;
    if (password !== confirm_password) {
      const errorMessage = document.getElementById("error_message");
      if (errorMessage) {
        errorMessage.classList.remove("hidden");
        errorMessage.innerHTML = "Lösenorden matchar inte";
      }
      return;
    }
    registerUser({ email, password, customerId });
  }
  return (
    <Layout>
      <div className="flex flex-row justify-center items-center h-screen">
        <div className="flex flex-col w-1/4">
          <h1 className="text-4xl font-bold text-center">{customer.name}</h1>
          <h2 className="text-3xl text-center mb-4">Registrera användare</h2>
          {customerId && customerId !== "" && (
            <div className="text-black flex flex-col space-y-4" id="form">
              <input type="text" placeholder="Email" id="email" className="p-2 border-b-2 border-gray-300 focus:outline-none" />
              <input type="password" placeholder="Lösenord" id="password" className="p-2 border-b-2 border-gray-300 focus:outline-none" />
              <input type="password" placeholder="Bekräfta lösenord" id="confirm_password" className="p-2 border-b-2 border-gray-300 focus:outline-none" />
              <button className="bg-blue-500 text-white p-2 rounded-md text-white bg-neutral-700 hover:border-neutral-400 hover:text-neutral-400 border-white border-2 shadow-none hover:shadow-none " onClick={save} >Registrera</button>
              <div id="error_message" className="text-center hidden text-red-400"></div>
            </div>
          )}
          <div id="registered_message" className="text-center hidden">
            <div> Tack för att du registrerade dig! </div> <div><a href="/login" className="text-blue-500">Logga in</a></div>
          </div>
          <div id="error_message" className="text-center hidden"></div>
        </div>
      </div>
    </Layout>
  );
}

export default Register;
