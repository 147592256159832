import React, { Component, ErrorInfo, ReactNode } from 'react';

interface Props {
  children: ReactNode;
  fallback?: ReactNode;
}

interface State {
  hasError: boolean;
  error: Error | null;
  errorInfo: ErrorInfo | null;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false,
    error: null,
    errorInfo: null
  };

  public static getDerivedStateFromError(error: Error): State {
    return { hasError: true, error, errorInfo: null };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({
      error,
      errorInfo
    });
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div className="error-boundary p-4 m-4 border-2 border-red-500 rounded bg-red-50 text-black">
          <h2 className="text-2xl font-bold text-red-700 mb-4">Något blev fel</h2>
          <div className="mb-4">
            <h3 className="font-bold mb-2">Fel:</h3>
            <pre className="bg-white p-4 rounded border border-red-200 whitespace-pre-wrap">
              {this.state.error?.toString()}
            </pre>
          </div>
          {this.state.errorInfo && (
            <div>
              <h3 className="font-bold mb-2">Komponent stack:</h3>
              <pre className="bg-white p-4 rounded border border-red-200 overflow-auto max-h-96">
                {this.state.errorInfo.componentStack}
              </pre>
            </div>
          )}
          <button
            className="mt-4 px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
            onClick={() => window.location.reload()}
          >
            Ladda om sidan
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
