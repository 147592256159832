import React from 'react';
import { createPortal } from 'react-dom';

interface BaseModalProps {
  onClose: () => void;
  title: string;
  children?: React.ReactNode;
  width?: string;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  onPrimaryAction?: () => void;
  onSecondaryAction?: () => void;
}

export const BaseModal: React.FC<BaseModalProps> = ({
  onClose,
  title,
  children,
  width = "w-[500px]",
  primaryButtonText = "Spara",
  secondaryButtonText = "Avbryt",
  onPrimaryAction,
  onSecondaryAction
}) => {

  return createPortal(
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className={`bg-neutral-800 rounded-lg p-4 ${width} shadow-lg relative`}>
        <h2 className="text-lg font-bold text-white mb-4">{title}</h2>
        
        {children}
        
        <div className="flex justify-end gap-2">
          {secondaryButtonText && (
            <button
              onClick={onSecondaryAction || onClose}
              className="px-4 py-2 text-white bg-neutral-600 rounded-md hover:bg-neutral-500"
            >
              {secondaryButtonText}
            </button>
          )}
          {primaryButtonText && (
            <button
              onClick={onPrimaryAction}
              className="px-4 py-2 text-white bg-blue-600 rounded-md hover:bg-blue-500"
            >
              {primaryButtonText}
            </button>
          )}
        </div>
      </div>
    </div>,
    document.body
  );
};