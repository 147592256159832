import ReactDOM from "react-dom/client";
import './index.css';
import '@fontsource/roboto';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Root from './routes/Root';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPiggyBank, faGem, faChartLine, faCirclePlus, faHouse, faFileInvoice, faFilePen, faSackXmark, faBullhorn, faPenToSquare, faClipboard } from '@fortawesome/free-solid-svg-icons';
import { faUser, faCreditCard } from '@fortawesome/free-regular-svg-icons';
import { ThemeProvider } from '@material-tailwind/react';
import { Provider } from 'react-redux';
import { store } from './infrastructure/store';
import Register from './routes/Register';
import Loaders from './routes/Loaders';
import Users from './routes/Users';
import Support from "./routes/Support";
import DataSources from './routes/DataSources';
import Login from './routes/Login';

library.add(faCreditCard, faPiggyBank, faGem, faChartLine, faCirclePlus, faHouse, faUser, faFileInvoice, faFilePen, faSackXmark, faBullhorn, faPenToSquare, faClipboard)

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
  },
  {
    path: "register",
    element: <Register />,
  },
  {
    path: "admin/loaders",
    element: <Loaders />,
  },
  {
    path: "admin/users",
    element: <Users />,
  },
  {
    path: "admin/support",
    element: <Support />,
  },
  {
    path: "login",
    element: <Login />,
  }, {
    path: "admin/data_sources",
    element: <DataSources />,
  },
]);

const App = () => {
  return (
    <Provider store={store}>
      <ThemeProvider>
        <RouterProvider router={router} />
      </ThemeProvider>
    </Provider>
  );
};

const root: HTMLElement | null = document.getElementById('root');
if (root) {
  ReactDOM.createRoot(root).render(
    <App />
  );
}

reportWebVitals();
